


































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { sort } from '@/utils/sort';
import { AdminAction, AdminActionType, AdminState } from '@/store/modules/admin/types';
import { AppState } from '@/store/modules/app/types';
import { Models } from '@mtap-smartcity/api';

const admin = namespace('admin');
const app = namespace('app');

@Component
/**
 * @group Users Card
 * Users tab right panel - Users list
 */
export default class UsersCardRightPanelUsers extends Vue {
  key = 0;

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @admin.State
  users!: AdminState['users'];

  @admin.State
  groups!: AdminState['groups'];

  @admin.State
  selectedGroup!: AdminState['selectedGroup'];

  @admin.Action(AdminAction.AddUserToGroup)
  addUserToGroup!: AdminActionType['ADD_USER_TO_GROUP'];

  get sortedUsers() {
    let result = this.users.sort((a, b) => sort(a.username, b.username));

    if (this.selectedGroup) {
      result = result.sort((a, b) => {
        if (
          a.groupId!.some((g) => g.id === this.selectedGroup!.id!)
          && !b.groupId!.some((g) => g.id === this.selectedGroup!.id!)
        ) {
          return -1;
        }
        return 1;
      });
    }
    return result;
  }

  groupName(user: Models.Bff.Auth.Users.Model) {
    let groupNames = (user.groupId as Models.Auth.Groups.GroupsModel[]).map(({ id }) => {
      const { name } = this.groups.find((g) => g.id === id) ?? { name: '' };
      return name;
    });
    groupNames = groupNames.filter((item) => item !== 'HISTORICALPARAMETERSMODE');
    return groupNames.join(' ');
  }

  belongsToSelectedGroup(user: Models.Bff.Auth.Users.Model) {
    if (!this.selectedGroup) return false;
    return user.groupId!.some((group) => group.id === this.selectedGroup?.id);
  }

  removeFromOtherGroups(userId: string, groups: Models.Auth.Groups.GroupsModel[]): void {
    if (!userId || !groups) return;
    groups!.forEach(async (group: Models.Auth.Groups.GroupsModel) => {
      const payload: Models.Auth.Groups.GroupsModel = {
        id: group!.id,
        name: group!.name,
        userId: userId!,
        userEnabled: false
      };
      try {
        await this.addUserToGroup(payload);
      } catch (error) {
        console.error(error);
      }
    });
  }

  async addToGroup(user: Models.Bff.Auth.Users.Model, switchLoader: () => void) {
    const addToGroup = !this.belongsToSelectedGroup(user);
    const currentGroups = [...user.groupId!];
    switchLoader();
    const payload: Models.Auth.Groups.GroupsModel = {
      id: this.selectedGroup!.id,
      name: this.selectedGroup!.name,
      userId: user.id!,
      userEnabled: addToGroup
    };
    try {
      await this.addUserToGroup(payload);
      if (addToGroup) this.removeFromOtherGroups(user.id!, currentGroups!);
      this.$emit('addUserToGroup', true);
    } catch (error) {
      console.error(error);
    }
    switchLoader();
  }
}
